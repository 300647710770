import React from 'react';
import { useIntl } from 'react-intl';
import { Error500 as HexaError500 } from '@hexa-ui/components';
import { Container } from './500.styles';

export function Error500(): JSX.Element {
  const { formatMessage } = useIntl();

  return (
    <Container data-testid="error-500">
      <HexaError500 headerText={formatMessage({ id: 'COMPONENTS.ERROR_GENERIC' })} />
    </Container>
  );
}
