export enum Countries {
  AR = 'AR',
  BE = 'BE',
  BR = 'BR',
  CA = 'CA',
  CO = 'CO',
  DE = 'DE',
  DO = 'DO',
  EC = 'EC',
  ES = 'ES',
  GB = 'GB',
  HN = 'HN',
  ID = 'ID',
  KR = 'KR',
  MX = 'MX',
  NL = 'NL',
  PA = 'PA',
  PE = 'PE',
  PH = 'PH',
  PY = 'PY',
  SV = 'SV',
  ZA = 'ZA',
}

export enum CountriesUS {
  US = 'US',
}

export enum CountriesDTC {
  AR = 'AR',
  BR = 'BR',
  BO = 'BO',
  CO = 'CO',
  DO = 'DO',
  EC = 'EC',
  HN = 'HN',
  MX = 'MX',
  PA = 'PA',
  PE = 'PE',
  PY = 'PY',
  SV = 'SV',
  ZA = 'ZA',
}

const MergedCountries = { ...Countries, ...CountriesDTC, ...CountriesUS };

export type AllCountries = keyof typeof MergedCountries;
export type SupportedCountries = Countries | CountriesDTC | CountriesUS;

export default Countries;
