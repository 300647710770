import Countries from 'domains/Countries';

export const GOV_ID_MAX_SIZE_BY_COUNTRY: { [key: string]: number } = {
  AR: 11,
  BR: 14,
  CA: 15,
  CO: 10,
  DO: 9,
  EC: 13,
  ES: 9,
  KR: 10,
  HN: 14,
  PE: 11,
  MX: 12,
  SV: 14,
  ZA: 12,
};

export const COUNTRY_BY_ACRONYM = {
  AR: 'ARGENTINA',
  BR: 'BRAZIL',
  CA: 'CANADA',
  CL: 'CHILE',
  CO: 'COLOMBIA',
  DO: 'DOMINICAN_REPUBLIC',
  EC: 'ECUADOR',
  EL: 'EL_SALVADOR',
  HN: 'HONDURAS',
  KR: 'SOUTH_KOREA',
  ES: 'CANARY_ISLANDS',
  MX: 'MEXICO',
  PA: 'PANAMA',
  PE: 'PERU',
  PY: 'PARAGUAY',
  TZ: 'TANZANIA',
  UY: 'URUGUAY',
  ZA: 'SOUTH_AFRICA',
};

export const EU_COUNTRIES = [
  Countries.BE,
  Countries.ES,
  Countries.DE,
  Countries.GB,
  Countries.ID,
  Countries.NL,
  Countries.PH,
];
