import { PatchAllVendorsParams } from 'domains/Stores';
import { EndpointsToLog, LogData } from 'features/log';

interface BaseLog {
  data: unknown;
  responseData?: unknown;
  extraData?: unknown;
}

interface LogCreateStoreEndpoint extends BaseLog {
  endpoint: EndpointsToLog.createStore;
  data: { name: string };
  responseData: { id: string };
}

interface LogUpdateVendorStoresEndpoint extends BaseLog {
  endpoint: EndpointsToLog.updateVendorStores;
  data: PatchAllVendorsParams['data'];
  extraData: PatchAllVendorsParams['logData'];
}

const endpointMapper = {
  [EndpointsToLog.createStore]: ({ data, responseData }: LogCreateStoreEndpoint) => ({
    storeId: responseData.id,
    storeName: data.name,
  }),
  [EndpointsToLog.updateVendorStores]: ({ data, extraData }: LogUpdateVendorStoresEndpoint) => ({
    stores: data,
    vendor: {
      id: extraData.vendorId,
      name: extraData.vendorName,
    },
  }),
} as {
  [key in EndpointsToLog]: (log: BaseLog) => LogData['data'];
};

export const getDataByEndpoint = ({
  endpoint,
  data,
  responseData,
  extraData,
}: LogUpdateVendorStoresEndpoint | LogCreateStoreEndpoint): LogData['data'] =>
  endpointMapper[endpoint]?.({ data, responseData, extraData });
