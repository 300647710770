import { combineReducers, configureStore } from '@reduxjs/toolkit';
import logReducer, { logListenerMiddleware } from 'features/log';

export const rootReducer = combineReducers({
  log: logReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['log/configLog'],
        ignoredPaths: ['log.auditLog'],
      },
    }).prepend(logListenerMiddleware.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
