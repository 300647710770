export const getCountryFromStoreURL = (url: string): string | null => {
  const regex = /\/stores\/([^/]+)/;
  const match = regex.exec(url);
  return match ? match[1] : null;
};

export const setCountryInCompanyUrl = (url: string, newCountry: string): string => {
  const regex = /\/companies\/[A-Za-z]{2}(\/?)/;
  const match = regex.exec(url);
  if (match) {
    return url.replace(regex, `/companies/${newCountry}${match[1]}`);
  }
  return url;
};
