import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import {
  createGenerateClassName,
  CssBaseline,
  StylesProvider,
  ThemeProvider,
} from '@material-ui/core';
import { useSidebar } from 'admin-portal-shared-services';
import { store } from 'app/store';
import { ClusterProvider } from 'context/cluster';
import { CountryProvider } from 'context/country';
import { configLog } from 'features/log';
import { useAuditLog } from 'hooks/useAuditLog';
import { useInitializeFeatureToggle } from 'hooks/useInitializeFeatureToggle';
import { resetSearch } from 'stores/vendor/VendorEvents';
import { configureStitches, muiTheme } from 'theme';
import { globalStyles } from 'theme/globalStyles';
import { QueryProvider } from 'utils/providers';
import useSegmentAnalytics from './hooks/useSegmentAnalytics/useSegmentAnalytics';
import IntlProvider from './i18n/provider';
import Router from './Router';

interface EnvVariables {
  segmentKey: string;
  optimizelyKey: string;
}

const App = ({ segmentKey, optimizelyKey }: EnvVariables): JSX.Element => {
  const generateClassName = createGenerateClassName({
    productionPrefix: 'vendor-management-mfe',
    seed: 'vendor-management-mfe',
  });

  globalStyles();

  const { isReady: showCompanyPage } = useInitializeFeatureToggle(optimizelyKey);

  useSegmentAnalytics(segmentKey);
  store.dispatch(configLog({ auditLog: useAuditLog() }));

  useSidebar({
    items: [
      {
        id: '',
        title: '',
        icon: function IconComponent() {
          return <></>;
        },
        path: '/',
      },
    ],
    utils: [],
  });

  useEffect(() => {
    configureStitches();
    return () => resetSearch();
  }, []);

  if (!showCompanyPage) {
    return <></>;
  }

  return (
    <QueryProvider>
      <Provider store={store}>
        <IntlProvider>
          <ClusterProvider>
            <CountryProvider>
              <ThemeProvider theme={muiTheme}>
                <StylesProvider generateClassName={generateClassName} injectFirst>
                  <CssBaseline />
                  <Router />
                </StylesProvider>
              </ThemeProvider>
            </CountryProvider>
          </ClusterProvider>
        </IntlProvider>
      </Provider>
    </QueryProvider>
  );
};

export default App;
