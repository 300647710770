import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AllCountries } from 'domains/Countries';
import { PatchStore } from 'domains/Stores';
import { AssociateDissociateMetadata, useAuditLog } from 'hooks/useAuditLog';

export enum EndpointsToLog {
  updateVendorStores = 'updateVendorStores',
  createStore = 'createStore',
}

interface BaseLog {
  country: AllCountries;
}

interface LogCreateData {
  storeId: string;
  storeName: string;
}

export interface LogCreateStore extends BaseLog {
  endpoint: EndpointsToLog.createStore;
  data: LogCreateData;
}

interface LogAssociateDissociateData {
  vendor: AssociateDissociateMetadata['vendor'];
  stores: PatchStore[];
}

export interface LogUpdateVendorStores extends BaseLog {
  endpoint: EndpointsToLog.updateVendorStores;
  data: LogAssociateDissociateData;
}

export type LogData = LogCreateStore | LogUpdateVendorStores;

type LogConfig = {
  auditLog: ReturnType<typeof useAuditLog>;
};

const logSlice = createSlice({
  name: 'log',
  initialState: {} as LogConfig & LogData,
  reducers: {
    startLog: (state, { payload }: PayloadAction<LogData>) => {
      state.endpoint = payload.endpoint;
      state.data = payload.data;
      state.country = payload.country;
    },
    configLog: (state, { payload }: PayloadAction<LogConfig>) => {
      state.auditLog = payload.auditLog;
    },
  },
});

export default logSlice.reducer;
export const { startLog, configLog } = logSlice.actions;
